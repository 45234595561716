import React from 'react';

import { helmetPageValues as hel } from '../utils/constants';
import { Layout, ContactLayout } from '../layouts';
import { ContactForm, Helmet } from '../components';

const Contact = () => {
  return (
    <Layout>
      <Helmet {...hel.contact} />
      <ContactLayout page="contact">
        <ContactForm />
      </ContactLayout>
    </Layout>
  )
}

export default Contact;
